.Home-container {
  height: 100vh;
  min-height: 480px;
  background-color: #212121;
  color: white;
}

#home:before{
  content: "<Home/>";
  font-family: Roboto Mono,Courier New,Courier,monospace!important;
  font-weight: bold;
  color: #ffffff45;
  position: absolute;
  right: 0px;
}

.Home-title{
  font-size: 27px;
  text-align: left;
}

.Home-subtitle{
  font-size: 23px;
  text-align: left;
}

.Home-icon {
  color: white;
  padding: 10px;
  font-size: 55px;
  width: 75px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

.Home-icon:hover {
  color: #c1c1c1;
  text-decoration: solid;
}

/*.Home-typewriter p{
  color: #fff;
  overflow: hidden;
  border-right: .15em solid orange;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation: typing 3.5s steps(30, end), blink-caret .5s step-end infinite;
}

@keyframes typing{
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}*/

@media all and (min-width: 1024px){
  #home:before{
    font-size: 114px;
  }
}
@media all and (min-width: 768px) and  (max-width: 1023px){
  #home:before{
    font-size: 100px;
  }
}
@media all and (min-width: 600px) and  (max-width: 767px){
  #home:before{
    font-size: 95px;
  }
}
@media all and (min-width: 500px) and  (max-width: 599px){
  #home:before{
    font-size: 90px;
  }
}
@media all and (max-width: 499px){
  #home:before{
    font-size: 70px;
  }
}

