.About2-container {
  height: 100vh;
  min-height: 480px;
  background-color: #4527a0;
  color: white;
}

#about2:before{
  content: "<About/>";
  font-family: Roboto Mono,Courier New,Courier,monospace!important;
  font-weight: bold;
  color: #ffffff45;
  position: absolute;
  right: 0px;
  top: 16px;
}

.About2-code-container {
  white-space: pre-wrap;
  background-color: #002b36;
  text-align: left;
  color: #b58900;
}

@media all and (min-width: 1024px){
  #about2:before{
    font-size: 100px;
    top: 60px;
  }
  .About2-row {
    padding-top: 11%;
  }
}
@media all and (min-width: 768px) and  (max-width: 1023px){
  #about2:before{
    font-size: 90px;
    top: 50px;
  }
  .About2-row {
    padding-top: 11%;
  }
}
@media all and (min-width: 600px) and  (max-width: 767px){
  #about2:before{
    font-size: 85px;
    top: 60px;
  }
  .About2-row {
    padding-top: 11%;
  }
}
@media all and (min-width: 500px) and  (max-width: 599px){
  #about2:before{
    font-size: 60px;
    top: 65px;
  }
  .About2-row {
    padding-top: 11%;
  }
  .About2-code-container {
    font-size: 15px;
  }
}
@media all and (max-width: 499px){
  #about2:before{
    font-size: 46px;
    top: 52px;
  }
  .About2-row {
    padding-top: 11%;
  }
  .About2-code-container {
    font-size: 11px;
  }
}

