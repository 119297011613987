body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.all-height {
  height: 100%;
}

.height-50 {
  height: 50%;
}

.text-align-left{
  text-align: left;
}
.text-align-right{
  text-align: right;
}
.text-align-center{
  text-align: center;
}

.no-padding{
  padding: 0 !important;
}

.no-margin{
  margin: 0 !important;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Home-container {
  height: 100vh;
  min-height: 480px;
  background-color: #212121;
  color: white;
}

#home:before{
  content: "<Home/>";
  font-family: Roboto Mono,Courier New,Courier,monospace!important;
  font-weight: bold;
  color: #ffffff45;
  position: absolute;
  right: 0px;
}

.Home-title{
  font-size: 27px;
  text-align: left;
}

.Home-subtitle{
  font-size: 23px;
  text-align: left;
}

.Home-icon {
  color: white;
  padding: 10px;
  font-size: 55px;
  width: 75px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

.Home-icon:hover {
  color: #c1c1c1;
  -webkit-text-decoration: solid;
          text-decoration: solid;
}

/*.Home-typewriter p{
  color: #fff;
  overflow: hidden;
  border-right: .15em solid orange;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation: typing 3.5s steps(30, end), blink-caret .5s step-end infinite;
}

@keyframes typing{
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}*/

@media all and (min-width: 1024px){
  #home:before{
    font-size: 114px;
  }
}
@media all and (min-width: 768px) and  (max-width: 1023px){
  #home:before{
    font-size: 100px;
  }
}
@media all and (min-width: 600px) and  (max-width: 767px){
  #home:before{
    font-size: 95px;
  }
}
@media all and (min-width: 500px) and  (max-width: 599px){
  #home:before{
    font-size: 90px;
  }
}
@media all and (max-width: 499px){
  #home:before{
    font-size: 70px;
  }
}


.About2-container {
  height: 100vh;
  min-height: 480px;
  background-color: #4527a0;
  color: white;
}

#about2:before{
  content: "<About/>";
  font-family: Roboto Mono,Courier New,Courier,monospace!important;
  font-weight: bold;
  color: #ffffff45;
  position: absolute;
  right: 0px;
  top: 16px;
}

.About2-code-container {
  white-space: pre-wrap;
  background-color: #002b36;
  text-align: left;
  color: #b58900;
}

@media all and (min-width: 1024px){
  #about2:before{
    font-size: 100px;
    top: 60px;
  }
  .About2-row {
    padding-top: 11%;
  }
}
@media all and (min-width: 768px) and  (max-width: 1023px){
  #about2:before{
    font-size: 90px;
    top: 50px;
  }
  .About2-row {
    padding-top: 11%;
  }
}
@media all and (min-width: 600px) and  (max-width: 767px){
  #about2:before{
    font-size: 85px;
    top: 60px;
  }
  .About2-row {
    padding-top: 11%;
  }
}
@media all and (min-width: 500px) and  (max-width: 599px){
  #about2:before{
    font-size: 60px;
    top: 65px;
  }
  .About2-row {
    padding-top: 11%;
  }
  .About2-code-container {
    font-size: 15px;
  }
}
@media all and (max-width: 499px){
  #about2:before{
    font-size: 46px;
    top: 52px;
  }
  .About2-row {
    padding-top: 11%;
  }
  .About2-code-container {
    font-size: 11px;
  }
}



